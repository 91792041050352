<template>
  <div>
    <div class="header">
      <ImageEraser class="canvas" />
      <img src="../assets/avatar.png" class="avatar" />
      <h1 class="headerText">Hi Im Maaz and Im a Javascript Developer</h1>
    </div>
    <ProjectsSection />
    <ThingsSection />
    <AboutSection />
  </div>
</template>

<script>
import ProjectsSection from "@/components/ProjectsSection.vue";
import ThingsSection from "@/components/ThingsSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import CustomTag from "../components/CustomTag.vue";
import ImageEraser from "../components/ImageEraser.vue";
import { useRouter } from "vue-router";

export default {
  name: "HomePage",
  created() {
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0);
      next();
    });
  },
  components: {
    CustomTag,
    ProjectsSection,
    ThingsSection,
    AboutSection,
    ImageEraser,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  padding: 150px;
  align-items: center;
  border-bottom: 10px solid black;
  position: relative;
}
.avatar {
  width: 150px;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
}
.headerText {
  width: 40%;
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 767px) {
  .header {
    padding: 100px 50px;
  }
  .avatar {
    width: 50px;
    position: inherit;
  }
  .headerText {
    font-size: 1rem;
    position: inherit;
  }
}
</style>
