<template>
  <Navbar />
  <Menu />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },

  components: {
    Navbar,
    Footer,
    Menu,
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
#app {
  font-family: babyFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  color: white;
}
button {
  border: none;
  background: none;
  height: min-content;
  cursor: pointer;
}
a {
  text-decoration: none;
}
@font-face {
  font-family: babyFont;
  src: url("./assets/BebasNeue-Regular.ttf");
}
/* * {
  outline: red dashed 1px;
} */
</style>
