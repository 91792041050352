import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../pages/HomePage.vue";
import ProjectsPage from "../pages/ProjectsPage.vue";
import GamesPage from "../pages/GamesPage.vue";
import ShopPage from "../pages/ShopPage.vue";
import ContactPage from "../pages/ContactPage.vue";

const routes = [
  { path: "/", name: "home", component: HomePage },
  { path: "/projects", name: "projects", component: ProjectsPage },
  { path: "/games", name: "games", component: GamesPage },
  { path: "/shop", name: "shop", component: ShopPage },
  { path: "/contact", name: "contact", component: ContactPage },
  { path: "/:pathMatch(.*)*", name: "redirect", component: HomePage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});
export default router;
