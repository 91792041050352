<template>
  <nav class="navbar" :class="{ scrolled: isScrolled, shown: showMenu }">
    <div class="topContainer">
      <h1 class="logo">
        <img class="favicon" src="../assets/favicon.png" />
        <router-link class="title" to="/">mezerio</router-link>
      </h1>

      <i @click="showMenu = !showMenu" class="fa-solid fa-bars"></i>
    </div>
    <ul class="navButtons" v-if="showMenu">
      <li class="navButton">
        <router-link @click="showMenu = !showMenu" class="navLink" to="/"
          >Home</router-link
        >
      </li>
      <li class="navButton">
        <router-link
          @click="showMenu = !showMenu"
          class="navLink"
          to="/projects"
          >Projects</router-link
        >
      </li>
      <li class="navButton">
        <router-link @click="showMenu = !showMenu" class="navLink" to="/games"
          >Games</router-link
        >
      </li>
      <li class="navButton">
        <router-link @click="showMenu = !showMenu" class="navLink" to="/shop"
          >Shop</router-link
        >
      </li>
      <li class="navButton">
        <router-link @click="showMenu = !showMenu" class="navLink" to="/contact"
          >Contact</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },

  data() {
    return {
      isScrolled: false,
      showMenu: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      this.isScrolled = scrollPosition > 100;
    },
  },
};
</script>

<style scoped>
.topContainer {
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}
.title {
  color: white;
  text-decoration: none;
  font-size: 2rem;
}

.logo {
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navbar {
  display: block;
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  padding: 1rem;
  transition: 0.3s;
  z-index: 1;
}

.navButtons {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 0;
}

.navLink {
  text-decoration: none;
  color: darkgrey;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.navLink:hover {
  cursor: pointer;
  color: #ea887e;
}

.myCart {
  color: lightgrey;
  align-items: center;
  display: flex;
  margin-left: 1rem;
}

.myCart:hover {
  color: #ff741e;
  cursor: pointer;
}
.active {
  color: #ff741e;
  font-weight: bold;
}
.scrolled {
  background-color: black;
}
.favicon {
  height: 2rem;
  margin-right: 8px;
}
.fa-bars {
  font-size: 2rem;
}

.shown {
  background-color: black;
}

@media (min-width: 768px) {
  .navbar {
    display: none;
  }
}
</style>
