<template>
  <div class="container">
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      Things I'm working on
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <CustomTag class="ul" :closeTag="false" :customTagName="'ul'" />
    <div class="list" id="listId">
      <CustomTag :closeTag="false" :customTagName="'li'" />
      <p>
        I am interested in machine learning and i learn best through doing a
        project on it myself and so i decided to take shmanks,a multi player
        game that i have made, and make an ai using python that you can play
        against.
      </p>
      <CustomTag :closeTag="true" :customTagName="'li'" />
      <CustomTag :closeTag="false" :customTagName="'li'" />
      <p>
        I am learning sql to widen my knowledge in databases and hopefully move
        on to working with aws and work in projects such as cloud migration.
      </p>
      <CustomTag :closeTag="true" :customTagName="'li'" />
    </div>
    <CustomTag class="ul" :closeTag="true" :customTagName="'ul'" />
  </div>
</template>

<script>
import CustomTag from "../components/CustomTag.vue";
export default {
  name: "ThingsSection",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    CustomTag,
  },
  methods: {
    isScrolledIntoView(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible;
    },
    handleScroll() {
      if (this.isScrolledIntoView(document.getElementById("listId"))) {
        document.getElementById("listId").classList.add("slide");
      }
    },
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin: 20px;
  font-weight: bold;
}
.container {
  padding: 30px 200px;
}
p {
  text-align: left;
  margin-left: 15px;
}
.list {
  margin-left: 15px;
  font-size: 1.5rem;
  opacity: 0;
}
.ul {
  font-size: 1.5rem;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide {
  animation: slideInFromLeft 0.5s;
  opacity: 1;
}
@media (max-width: 767px) {
  .container {
    padding: 10px;
  }
  p {
    font-size: 1rem;
  }
}
</style>
