<template>
  <div class="card" :id="project.name">
    <div class="title">
      <CustomTag
        :closeTag="false"
        :customClassName="project.class"
        :customTagName="'div'"
      />
      <p>{{ project.name }}</p>
      <CustomTag :closeTag="true" :customTagName="'div'" />
    </div>
    <div class="container">
      <div class="leftContainer">
        <img :src="require(`../assets/${project.badge}`)" />
        <div v-if="project.tags.includes('game')" class="playerCount">
          <i class="fa-solid fa-user"></i>
          <p class="playerCount">{{ project.playerCount }}</p>
        </div>
      </div>
      <div class="descContainer">
        <CustomTag :closeTag="false" :customTagName="'p'" />
        <p class="desc">{{ project.description }}</p>
        <CustomTag :closeTag="true" :customTagName="'p'" />
      </div>
    </div>
    <div class="bottomContainer">
      <a :href="project.githubLink" target="blank">
        <i class="fa-brands fa-github"></i>
      </a>
      <router-link to="/projects">
        <button class="infoBtn">more info</button>
      </router-link>

      <a :href="project.website" target="blank">
        <button class="playBtn">
          {{ project.class == "games" ? "Play" : "Visit" }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import CustomTag from "./CustomTag.vue";
import ProjectDetailsCard from "./ProjectDetailsCard.vue";
export default {
  name: "ProjectCard",
  mounted() {
    this.handleScroll();
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomTag,
    ProjectDetailsCard,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isScrolledIntoView(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible;
    },
    handleScroll() {
      if (this.isScrolledIntoView(document.getElementById(this.project.name))) {
        document.getElementById(this.project.name).classList.add("slide");
      }
    },
  },
};
</script>

<style scoped>
.playBtn {
  background-color: #ea887e;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
}

.infoBtn {
  background-color: grey;
  padding: 2px 10px;
  border-radius: 5px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
i {
  color: white;
  font-size: 2rem;
  margin: 5px;
  transition: 0.3s;
}
.fa-github:hover {
  color: #ea887e;
}

.title,
.container {
  display: flex;
}

.desc {
  text-align: left;
  margin-left: 10px;
  height: 6rem;
  overflow: scroll;
}
.descContainer,
.leftContainer {
  padding: 10px;
  padding-bottom: 0;
}
.card {
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  transition: 0.3s;
  width: 350px;
  opacity: 0;
  box-shadow: 0px 5px 8px #00000080;
}
.card:hover {
  transform: scale(1.1);
}
img {
  width: 50px;
  margin-bottom: 20px;
}
.playerCount {
  background-color: #c1edff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: black;
}
.fa-user {
  font-size: 0.9rem;
  color: black;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide {
  animation: slideInFromLeft 0.5s;
  opacity: 1;
}

@media (max-width: 767px) {
  .card {
    background-color: black;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    transition: 0.3s;
    width: -webkit-fill-available;
  }
  .card:hover {
    transform: none;
  }
}
</style>
