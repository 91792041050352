<template>
  <div class="container">
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>games</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <p class="gameSectionTitle">web games</p>
    <div class="projects">
      <GameCard
        v-for="(project, index) in projects.filter(
          (project) =>
            project.tags.includes('game') && project.tags.includes('web app')
        )"
        :project="project"
        :key="index"
      />
    </div>
    <!-- <div class="sepp"></div> -->
    <p class="gameSectionTitle">game apps</p>
    <div class="projects">
      <GameCard
        v-for="(project, index) in projects.filter(
          (project) =>
            project.tags.includes('game') && project.tags.includes('mobile app')
        )"
        :project="project"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import CustomTag from "../components/CustomTag.vue";
import GameCard from "../components/GameCard.vue";
import projects from "../assets/data.json";
import { useRouter } from "vue-router";

export default {
  name: "GamesPage",
  created() {
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0);
      next();
    });
  },
  data() {
    return {
      projects: projects,
    };
  },
  components: {
    CustomTag,
    GameCard,
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
}
.container {
  margin: 30px 50px;
  margin-top: 100px;
}
.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #1d1c1c;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
}
.gameSectionTitle {
  border-radius: 5px 5px 0 0;
  background-color: #1d1c1c;
  color: #e5c585;
  padding: 5px;
}
@media (max-width: 767px) {
  .container {
    margin: 0;
    margin-top: 100px;
  }
}
</style>
