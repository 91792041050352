<template>
  <div>
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>shop</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <div class="shopContainer">
      <i class="fa-solid fa-shop-slash"></i>
      <p>the shop is under refurbishment :((</p>
    </div>
  </div>
</template>

<script>
import CustomTag from "@/components/CustomTag.vue";
import { useRouter } from "vue-router";

export default {
  name: "ShopPage",
  created() {
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0);
      next();
    });
  },
  components: {
    CustomTag,
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
  margin-top: 100px;
}
.shopContainer {
  padding: 200px;
  display: flex;
  justify-content: center;
}
i {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .shopContainer {
    padding: 300px 0;
    display: flex;
    justify-content: center;
    min-height: 100%;
  }
}
</style>
