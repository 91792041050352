<template>
  <div class="container">
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>About</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <div class="aboutTextContainer" id="aboutTextId">
      <CustomTag :closeTag="false" :customTagName="'p'" />

      <p class="aboutText">
        My friends and i like socialising while playing board games and during
        the pandemic it became difficult to meet up and play them in person. i
        sought of a way to play them online however most of our usual favourites
        did not have a online counterpart. due to this and the extra free time i
        had i decided to try my hand at learning to code and make one myself. I
        was a novice as i had not learnt coding during my time in education
        however i did study maths and stats at university of warwick and so i
        was familiar with the logical reasoning and had the skill to grasp the
        content easily. I really endjoyed the procress of making the project and
        continued learning and exploring more in the field. From the vast amount
        of frameworks available for Javascript I enjoy working with Vue.js and
        React Native the most and would like to pursue a role as a software
        developer involving those technologies.
      </p>
      <CustomTag :closeTag="true" :customTagName="'p'" />
    </div>
  </div>
</template>

<script>
import CustomTag from "../components/CustomTag.vue";
export default {
  name: "AboutSection",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    CustomTag,
  },
  methods: {
    isScrolledIntoView(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible;
    },
    handleScroll() {
      if (this.isScrolledIntoView(document.getElementById("aboutTextId"))) {
        document.getElementById("aboutTextId").classList.add("slide");
      }
    },
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin: 20px;
  font-weight: bold;
}
.aboutText {
  text-align: left;
  margin-left: 10px;
}
.aboutTextContainer {
  font-size: 1.5rem;
  opacity: 0;
}
.container {
  padding: 30px 200px;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide {
  animation: slideInFromLeft 0.5s;
  opacity: 1;
}

@media (max-width: 767px) {
  .container {
    padding: 10px;
  }
  p {
    font-size: 1rem;
  }
}
</style>
