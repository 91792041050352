<template>
  <div class="card" :id="project.name">
    <div class="title">
      <CustomTag
        :closeTag="false"
        :customClassName="project.class"
        :customTagName="'div'"
      />
      <p>{{ project.name }}</p>
      <CustomTag :closeTag="true" :customTagName="'div'" />
    </div>
    <div class="container">
      <img :src="require(`../assets/${project.badge}`)" />
      <div v-if="project.tags.includes('game')" class="playerCount">
        <i class="fa-solid fa-user"></i>
        <p class="playerCount">{{ project.playerCount }}</p>
      </div>
      <a
        :href="project.website !== '' ? project.website : project.githubLink"
        target="blank"
      >
        <button class="playBtn">
          {{ project.class == "games" ? "Play" : "Download" }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import CustomTag from "./CustomTag.vue";
export default {
  name: "GameCard",
  mounted() {
    document.getElementById(this.project.name).classList.add("slide");
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomTag,
  },
};
</script>

<style scoped>
.playBtn {
  background-color: #ea887e;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
}

i {
  color: white;
  font-size: 2rem;
  margin: 5px;
}

.title {
  display: flex;
  padding: 5px;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  align-items: center;
}

.card {
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  transition: 0.3s;
  width: 350px;
  opacity: 0;
  box-shadow: 0px 5px 8px #00000080;
}
.card:hover {
  transform: scale(1.1);
}
img {
  width: 50px;
}
.playerCount {
  background-color: #c1edff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: black;
  padding: 0 5px;
}
.fa-user {
  font-size: 0.9rem;
  color: black;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide {
  animation: slideInFromLeft 0.5s;
  opacity: 1;
}
@media (max-width: 767px) {
  .card {
    background-color: black;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    transition: 0.3s;
    width: -webkit-fill-available;
  }
  .card:hover {
    transform: none;
  }
}
</style>
