<template>
  <div class="footer">
    <div>
      <CustomTag
        :closeTag="false"
        :customTagName="'ul'"
        :customClassName="'Contact Me'"
      />
    </div>
    <a target="blank" href="mailto:maazvali@hotmail.co.uk">
      &nbsp;&nbsp;&nbsp;<CustomTag
        :closeTag="false"
        :customTagName="'li'"
        :customIdName="'myEmail'"
      />
      maazvali@hotmail.co.uk<CustomTag :closeTag="true" :customTagName="'li'" />
    </a>
    <a target="blank" href="https://www.linkedin.com/in/maaz-vali/">
      &nbsp;&nbsp;&nbsp;<CustomTag
        :closeTag="false"
        :customTagName="'li'"
        :customIdName="'myLinkedIn'"
      />
      maazvali<CustomTag :closeTag="true" :customTagName="'li'" />
    </a>
    <a target="blank" href="https://github.com/mezerio">
      &nbsp;&nbsp;&nbsp;<CustomTag
        :closeTag="false"
        :customTagName="'li'"
        :customIdName="'myGithub'"
      />
      mezerio<CustomTag :closeTag="true" :customTagName="'li'" />
    </a>
    <div><CustomTag :closeTag="true" :customTagName="'ul'" /></div>
  </div>
</template>

<script>
import CustomTag from "./CustomTag.vue";
export default {
  name: "Footer",
  components: {
    CustomTag,
  },
};
</script>

<style scoped>
a {
  display: flex;
  margin: 5px;
}
.footer {
  width: -webkit-fill-available;
  background-color: black;
  padding: 20px;
  display: block;
}
</style>
