<template>
  <div class="container">
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>projects</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <div class="tags">
      <ul>
        <li
          v-for="(tag, index) in tags"
          :key="index"
          @click="handleFilter(tag)"
          :class="{
            notActiveFilter: filter != '',
            activeFilter: tag === filter,
          }"
        >
          {{ tag }}
        </li>
      </ul>
    </div>
    <transition name="fade" mode="out-in">
      <div class="projects" :key="filter">
        <ProjectDetailsCard
          v-for="(project, index) in projects.filter((project) => {
            if (filter != '') {
              return project.tags.includes(filter);
            } else {
              return true;
            }
          })"
          :project="project"
          :key="index"
          :filter="filter"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CustomTag from "../components/CustomTag.vue";
import ProjectDetailsCard from "../components/ProjectDetailsCard.vue";
import projects from "../assets/data.json";
import { useRouter } from "vue-router";

export default {
  name: "ProjectsPage",
  created() {
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0);
      next();
    });
  },
  data() {
    return {
      el: document.getElementById("project"),
      projects: projects,
      filter: "",
      tags: [
        "javascript",
        "typescript",
        "vue.js",
        "angular",
        "react native",
        "react",
        "web app",
        "mobile app",
        "redux",
        "e-commerce",
        "game",
        "node.js",
        "html",
        "css",
        "firebase",
        "heroku",
        "socket.io",
        "script",
        "expo",
        "python",
      ],
    };
  },
  components: {
    CustomTag,
    ProjectDetailsCard,
  },

  methods: {
    handleFilter(tag) {
      if (this.filter == tag) {
        this.filter = "";
      } else {
        this.filter = tag;
      }
    },
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
}
.container {
  margin: 30px 50px;
  margin-top: 100px;
}
.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
li {
  list-style-type: none;
  margin: 10px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  transition: 0.5s;
}
.notActiveFilter {
  color: grey;
  border-color: grey;
}
li:hover,
.activeFilter {
  color: lightgreen;
  border-color: lightgreen;
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
@media (max-width: 767px) {
  .container {
    margin: 0;
    margin-top: 100px;
  }
  ul {
    padding: 0;
  }
  li {
    margin: 5px;
  }
  li:hover {
    color: white;
    border-color: white;
  }
}
</style>
