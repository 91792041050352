<template>
  <div class="card">
    <div class="title">
      <CustomTag
        :closeTag="false"
        :customClassName="project.class"
        :customTagName="'div'"
      />
      <p>{{ project.name }}</p>
      <CustomTag :closeTag="true" :customTagName="'div'" />
    </div>
    <ul class="tags">
      <li
        v-for="(tag, index) in project.tags"
        :key="index"
        :class="{
          activeFilter: tag === filter,
        }"
      >
        {{ tag }}
      </li>
    </ul>
    <div class="container">
      <div class="leftContainer">
        <img class="badge" :src="require(`../assets/${project.badge}`)" />
      </div>
      <div class="descContainer">
        <CustomTag :closeTag="false" :customTagName="'p'" />
        <p class="desc">{{ project.description }}</p>
        <CustomTag :closeTag="true" :customTagName="'p'" />
      </div>
    </div>
    <div class="carasol">
      <div
        class="carItem"
        v-for="(image, index) in project.images"
        :key="index"
      >
        <div
          v-if="isLoading(index)"
          :class="{
            placeholderSmall: image.class == 'carImage',
            placeholderBig: image.class !== 'carImage',
          }"
        ></div>
        <img
          :class="[image.class, { hide: isLoading(index) }]"
          :src="require(`../assets/${image.path}`)"
          @load="imageLoaded(index)"
        />
        <div class="carTextContainer">
          <p class="carText">{{ image.desc }}</p>
        </div>
      </div>
    </div>
    <div class="bottomContainer">
      <a :href="project.githubLink" target="blank">
        <i class="fa-brands fa-github"></i>
      </a>
      <div v-if="project.tags.includes('game')" class="playerCount">
        <i class="fa-solid fa-user"></i>
        <p class="playerCount">{{ project.playerCount }}</p>
      </div>
      <a
        :href="project.website !== '' ? project.website : project.githubLink"
        target="blank"
        :class="project.class == 'mobileApps' ? 'disable' : ''"
      >
        <button class="playBtn">
          {{ getButtonText(project) }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import CustomTag from "./CustomTag.vue";
export default {
  name: "ProjectDetailsCard",
  data() {
    return {
      loadedImages: [],
    };
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
  },
  components: {
    CustomTag,
  },
  methods: {
    isLoading(index) {
      return !this.loadedImages.includes(index);
    },
    imageLoaded(index) {
      if (!this.loadedImages.includes(index)) {
        this.loadedImages.push(index);
      }
    },
    getButtonText(project) {
      switch (true) {
        case project.class == "games":
          return "Play";
        case project.class == "mobileApps":
          return "Download";
        default:
          return "Visit";
      }
    },
  },
};
</script>

<style scoped>
.playBtn {
  background-color: #ea887e;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
}

.bottomContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}
i {
  color: white;
  font-size: 2rem;
  margin: 5px;
  transition: 0.3s;
}

.title,
.container {
  display: flex;
}

.desc {
  text-align: left;
  margin-left: 10px;
}
.descContainer,
.leftContainer {
  padding: 10px;
  padding-bottom: 0;
}
.card {
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  transition: 0.3s;
  width: -webkit-fill-available;
  box-shadow: 0px 5px 8px #00000080;
}

.badge {
  width: 50px;
  margin-bottom: 20px;
}
.playerCount {
  background-color: #c1edff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: black;
  padding: 0 5px;
}
.fa-user {
  font-size: 0.9rem;
  color: black;
}
.fa-github:hover {
  color: #ea887e;
}

.carasol {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: all 1s linear;
}
.carItem {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
  margin: 0 5px;
}
.carTextContainer {
  opacity: 0;
  position: absolute;
  bottom: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  align-items: flex-end;
  transition: 0.3s;
}
.carTextContainer:hover {
  opacity: 1;
}
.carText {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  width: -webkit-fill-available;
  text-align: left;
}
.carImage {
  height: 240px;
  width: auto;
}
.carImageBig {
  height: 350px;
  width: auto;
}
.carImageMobile {
  height: 350px;
  width: auto;
  margin: 5px;
}
.tags {
  display: flex;
  font-size: 0.7rem;
  flex-wrap: wrap;
}

li {
  list-style-type: none;
  margin: 10px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  transition: 0.5s;
}
.activeFilter {
  color: lightgreen;
  border-color: lightgreen;
  cursor: pointer;
}
.disable {
  opacity: 0.5;
}

.placeholderSmall {
  height: 240px;
  background-color: #1a1919;
  position: absolute;
  width: inherit;
}
.placeholderBig {
  height: 350px;
  background-color: #1a1919;
  position: absolute;
  width: inherit;
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.placeholderSmall::after,
.placeholderBig::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: transparent;
  animation: loadingAnimation 1s ease-in-out infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
}

.hide {
  opacity: 0;
}
@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  li {
    margin: 5px;
  }
  .desc {
    text-align: left;
    margin-left: 10px;
    height: 6rem;
    overflow: scroll;
  }
}
</style>
