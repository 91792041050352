<template>
  <div class="condensation">
    <canvas class="canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      const image = new Image();
      image.src = require("@/assets/water2.png");
      image.onload = () => {
        this.initializeCanvas(image);
      };
    },
    initializeCanvas(image) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      const container = canvas.parentNode;
      const { width, height } = container.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;
      // Load the image

      // Draw the image on the canvas

      context.drawImage(image, 0, 0, width, height);

      // Mouse event handlers
      let isMouseDown = false;
      let lastX = 0;
      let lastY = 0;

      canvas.addEventListener("mousedown", (event) => {
        isMouseDown = true;
        const { offsetX, offsetY } = event;
        lastX = offsetX;
        lastY = offsetY;
      });

      canvas.addEventListener("mouseup", () => {
        isMouseDown = false;
      });

      canvas.addEventListener("mousemove", (event) => {
        if (!isMouseDown) return;

        const { offsetX, offsetY } = event;

        // Set global composite operation to "destination-out"
        context.globalCompositeOperation = "destination-out";

        // Set the brush properties
        const radius = 15; // Set the radius of the erasing brush
        const strength = 0.05; // Set the strength of the erasing effect

        // Calculate the gradient transparency
        const distance = Math.sqrt(
          (offsetX - lastX) ** 2 + (offsetY - lastY) ** 2
        );
        const angle = Math.atan2(offsetY - lastY, offsetX - lastX);
        const stepX = (offsetX - lastX) / distance;
        const stepY = (offsetY - lastY) / distance;

        for (let i = 0; i < distance; i++) {
          const x = lastX + stepX * i;
          const y = lastY + stepY * i;

          const gradient = context.createRadialGradient(
            x,
            y,
            radius * strength,
            x,
            y,
            radius
          );
          gradient.addColorStop(0, "rgba(0, 0, 0, 1)");
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          // Apply the gradient transparency to the brush stroke
          context.fillStyle = gradient;
          context.beginPath();
          context.arc(x, y, radius, 0, 2 * Math.PI);
          context.fill();
        }

        lastX = offsetX;
        lastY = offsetY;

        // Reset global composite operation
        context.globalCompositeOperation = "source-over";
      });

      // Reset the path when the mouse leaves the canvas
      canvas.addEventListener("mouseleave", () => {
        context.beginPath();
      });
    },
  },
};
</script>
<style scoped>
.canvas {
  display: block;
  width: 100%;
  height: 100%;
}
.condensation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
