<template>
  <nav class="navbar" :class="{ scrolled: isScrolled }">
    <h1 class="logo">
      <img class="favicon" src="../assets/favicon.png" />
      <router-link class="title" to="/">mezerio</router-link>
    </h1>

    <ul class="navButtons">
      <li class="navButton">
        <router-link
          class="navLink"
          :class="{ activeTab: isActive('/') }"
          to="/"
          >Home</router-link
        >
      </li>
      <li class="navButton">
        <router-link
          class="navLink"
          :class="{ activeTab: isActive('/projects') }"
          to="/projects"
          >Projects</router-link
        >
      </li>
      <li class="navButton">
        <router-link
          class="navLink"
          :class="{ activeTab: isActive('/games') }"
          to="/games"
          >Games</router-link
        >
      </li>
      <li class="navButton">
        <router-link
          class="navLink"
          :class="{ activeTab: isActive('/shop') }"
          to="/shop"
          >Shop</router-link
        >
      </li>
      <li class="navButton">
        <router-link
          class="navLink"
          :class="{ activeTab: isActive('/contact') }"
          to="/contact"
          >Contact</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navbar",

  data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isActive(route) {
      return this.$route.path == route;
    },
    handleScroll() {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      this.isScrolled = scrollPosition > 100;
    },
  },
};
</script>

<style scoped>
.title {
  color: white;
  text-decoration: none;
  font-size: 2rem;
}

.logo {
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  padding: 1rem 2.4rem;
  transition: 0.3s;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.navButtons {
  display: flex;
  list-style-type: none;
}

.navLink {
  text-decoration: none;
  color: darkgrey;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-left: 3.4rem;
  transition: all 0.3s ease;
}

.navLink:hover {
  cursor: pointer;
  color: #ea887e;
}

.myCart {
  color: lightgrey;
  align-items: center;
  display: flex;
  margin-left: 1rem;
}

.myCart:hover {
  color: #ff741e;
  cursor: pointer;
}
.active {
  color: #ff741e;
  font-weight: bold;
}
.scrolled {
  background-color: black;
}
.favicon {
  height: 2rem;
  margin-right: 8px;
}
.activeTab {
  color: #ea887e;
}

@media (max-width: 767px) {
  .navbar {
    display: none;
  }
}
</style>
