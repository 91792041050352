<template>
  <div>
    <span style="color: #979797; font-family: arial">&lt;</span>
    <span v-if="closeTag" style="color: #979797; font-family: arial">/</span>
    <span style="color: #a1e3ff; font-family: arial">{{ customTagName }}</span>
    <div v-if="customClassName != null">
      <span style="color: #c1edff; font-family: arial">&nbsp;class&nbsp;</span
      >=<span style="color: #ea887e; font-family: arial"
        >&nbsp;"{{ customClassName }}"</span
      >
    </div>
    <div v-if="customIdName != null">
      <span style="color: #c1edff; font-family: arial">&nbsp;id&nbsp;</span
      >=<span style="color: #ea887e; font-family: arial"
        >&nbsp;"{{ customIdName }}"</span
      >
    </div>
    <span style="color: #979797; font-family: arial">&gt;</span>
  </div>
</template>

<script>
export default {
  name: "CustomTag",
  props: {
    customTagName: String,
    customClassName: String,
    customIdName: String,
    closeTag: Boolean,
  },
};
</script>

<style scoped>
div {
  display: flex;
}
</style>
