<template>
  <div>
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>Contact</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <div class="wrapp">
      <div class="container" id="containerId">
        <img src="../assets/avatar.png" />
        <div class="icons">
          <a target="blank" href="https://github.com/mezerio" class="contact">
            <i class="fa-brands fa-github"></i> get in touch on github
          </a>
          <a
            target="blank"
            href="https://www.linkedin.com/in/maaz-vali/"
            class="contact"
          >
            <i class="fa-brands fa-linkedin"></i> message me on linked in
          </a>
          <a
            target="blank"
            href="mailto:maazvali@hotmail.co.uk"
            class="contact"
          >
            <i class="fa-solid fa-envelope"></i> send me an email
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTag from "@/components/CustomTag.vue";
import { useRouter } from "vue-router";

export default {
  name: "ContactPage",
  mounted() {
    document.getElementById("containerId").classList.add("slide");
  },
  created() {
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0);
      next();
    });
  },
  components: {
    CustomTag,
  },
};
</script>

<style scoped>
.wrapp {
  display: flex;
  width: 100%;
  justify-content: center;
}
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
  margin-top: 100px;
}
img {
  width: 150px;
  height: auto;
  margin: 20px;
}
i {
  font-size: 3rem;
  display: block;
  margin: 10px;
  transition: 0.5s;
}
i:hover,
.contact:hover {
  color: #ea887e;
  cursor: pointer;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: black;
  border-radius: 20px;
  width: max-content;
  padding: 20px;
  opacity: 0;
  box-shadow: 0px 5px 8px #00000080;
}
.contact {
  display: flex;
  align-items: center;
  transition: 0.5s;
  font-size: 1.5rem;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide {
  animation: slideInFromLeft 0.5s;
  opacity: 1;
}
@media (max-width: 767px) {
  .container {
    margin: 200px 10px;
  }
  .contact {
    font-size: 1rem;
  }
  img {
    width: 100px;
  }
}
</style>
