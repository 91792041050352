<template>
  <div class="container">
    <div class="titleTag">
      <CustomTag :closeTag="false" :customTagName="'h1'" />
      <p>projects</p>
      <CustomTag :closeTag="true" :customTagName="'h1'" />
    </div>
    <div class="projects">
      <ProjectCard
        v-for="(project, index) in projects.slice(0, 6)"
        :project="project"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import CustomTag from "../components/CustomTag.vue";
import ProjectCard from "../components/ProjectCard.vue";
import projects from "../assets/data.json";

export default {
  name: "ProjectsSection",
  data() {
    return {
      projects: projects,
    };
  },
  components: {
    CustomTag,
    ProjectCard,
  },
};
</script>

<style scoped>
.titleTag {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
}
.container {
  margin: 30px 50px;
}
.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .container {
    margin: 0;
  }
}
</style>
